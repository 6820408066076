<template>
  <!--begin::Basic info-->
  <div class="card bg-light mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div class="d-flex flex-column w-100">
          <div class="d-flex overflow-auto h-55px mb-7">
            <ul
              class="
                nav nav-stretch nav-line-tabs nav-line-tabs-2x
                border-transparent
                fs-5
                fw-bolder
                flex-nowrap
              "
            >
              <!--begin::Nav item-->
              <li class="nav-item">
                <router-link
                  to="/result/pks"
                  class="nav-link text-active-default me-6"
                  active-class="active"
                >
                  Real Count PKS
                </router-link>
              </li>
              <!--end::Nav item-->
              <!--begin::Nav item-->
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-default me-6"
                  to="/result/summary"
                  active-class="active"
                >
                  Summary Real Count
                </router-link>
              </li>
              <!--end::Nav item-->
            </ul>
          </div>
        </div>
        <!--end::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body border-top p-9">
      <router-view />
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Basic info-->
</template>

<script>
export default {};
</script>
